.filters {
  background-color: darken(#f0f4f8, 5%);
  padding: 20px;
  .fields-block {
    display: flex;
  }
  .field {
    flex: 1;
    margin-bottom: 0;
    label {
      margin-left: 6px;
      margin-bottom: 0px;
    }
  }
  .v-select .vs__dropdown-toggle {
    padding: 6px 10px;
  }
  .field-block > input,
  .field-date-interval > div input,
  .form-select {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 10px;
    background-color: #ffffff;
    border-radius: 0.375rem;
    border: 1px solid #d7dae2;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
  }

  .separator {
    width: 100%;
    border-bottom: 1px dashed rgba(#000, 0.2);
    margin-top: 18px;
    margin-bottom: 12px;
  }
  .form-control {
    background-color: #fff;
    border: 1px solid #d7dae2;
    box-shadow: 0px 2px 3px rgba(#000, 0.05);
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    position: relative;
    top: 1px;
    right: 6px;
    margin: 0;
    padding: 0;
    opacity: 0.6;
  }
}
