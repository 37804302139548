.modal-body {
  padding-top: 60px;
  .modal-footer {
    margin: 1.6rem -1rem -1rem;

  }
  .field {
    align-items: center;
    .v-select {
      min-width: 300px;
    }
    label {
      color: $primary;
      text-align: center;
      font-size: 30px;
      margin-bottom: 22px;
    }
  }
}
.modal-content {
  .modal-footer {
    border-top: none;
    justify-content: center;
    padding-bottom: 60px;
  }
}

.modal-search {
  position: sticky;
  top: -1rem;
  z-index: 2;
  margin: -1rem -1rem 1rem;
  padding: 1rem;
  background-color: darken(#f1f1f1, 10%);
}

.modal + .modal-backdrop {
  background-color: #748A9D;
  opacity: .95;
}

.media-block {
  border: 2px solid transparent;
  border-radius: 4px;
  padding: 4px;
  background-color: #fff;
  position: relative;
  margin-bottom: 14px;
  cursor: pointer;

  &.media-block-selected {
    border-color: $primary;
    &:before {
      content: "\F00C";
      font-family: "Font Awesome 6 Pro";
      font-weight: 900;
      position: absolute;
      z-index: 2;
      width: 26px;
      height: 26px;
      background-color: $primary;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #fff;
      outline: 1px solid $primary;
      top: -8px;
      right: -8px;
      border-radius: 3px;
    }
  }
  .media-block-img {
    height: 250px;
    background-color: #dcdcdc;
    position: relative;
    .media-block-img-mask {
      display: none;
      animation-duration: 0.2s;
      animation-fill-mode: both;
      span {
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-name: bounceIn;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 46px;
        border-radius: 100%;
        background-color: #fff;
        i {
          font-size: 24px;
          color: $primary;
        }
      }
    }
    &:hover {
      .media-block-img-mask {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(#fff, 0.6);
        animation-name: fadeIn;
      }
    }
  }
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
}

.modal-library {
  .modal-body {
    background-color: #f1f1f1;
    .media-block {
      .media-block-title {
        padding: 8px 6px;
      }
    }
  }
}


.modal-content {
  border-radius: 0;
}