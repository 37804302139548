$step-bg-style: "solid"; // solid ou transparent
$mobile-steps-transparent-padding-b: 16px;
$steps-transparent-padding-b: 0;

// Steps
$mobile-step-orientation: "vertical";
$step-orientation: "vertical";
$mobile-steps-padding-t: 0;
$mobile-steps-padding-b: $mobile-steps-padding-t;
$mobile-steps-padding-l: 20px;
$mobile-steps-padding-r: $mobile-steps-padding-l;
$mobile-steps-padding-vertical: $mobile-steps-padding-t;
$mobile-steps-padding-horizontal: $mobile-steps-padding-l;
$mobile-steps-padding: $mobile-steps-padding-t $mobile-steps-padding-r $mobile-steps-padding-b $mobile-steps-padding-l;
$steps-padding-t: 0;
$steps-padding-b: $steps-padding-t;
$steps-padding-l: 0;
$steps-padding-r: $steps-padding-l;
$steps-padding-vertical: $steps-padding-t;
$steps-padding-horizontal: $steps-padding-l;
$steps-padding: $steps-padding-t $steps-padding-r $steps-padding-b $steps-padding-l;
$mobile-steps-margin: 10px 0;
$steps-margin: 0 0;


  // Step
    // Step Content
    $mobile-step-content-size: 18px;
    $mobile-step-content-color: $secondary;
    $mobile-step-content-weight: 500;
    $mobile-step-content-style: normal;
      $step-content-size: 18px;
      $step-content-color: rgba($secondary,.8);
      $step-content-weight: 500;
      $step-content-style: normal;
    $step-content-align: center;
    $mobile-step-content-margin-t: 6px;
    $step-content-margin-t: 10px;
    $step-content-margin-l: 10px;
    $step-subtext-size: 12px;
    $step-subtext-weight: $step-content-weight;
    $step-subtext-style: italic;
    $isStepTextWidthConstrained: "no"; // yes or no if yes => width else text nowrap
    $step-content-width: 90px;
    
    // Step Icon
    $isIconStyle: "fonticon"; // sprite, number, fonticon, bullet
    $stepIconFontFamily: "fontawesome"; // fontawesome, glbicons
    $mobile-step-icon-width: 36px;
    $mobile-step-icon-height: 36px;
      $step-icon-width: 36px;
      $step-icon-height: 36px;
    $step-icon-radius: 100%;
    $step-icon-bg-color: transparent;
    $mobile-step-icon-border-width: 1px;
    $mobile-step-icon-border-style: solid;
    $mobile-step-icon-border-color: $secondary;
    $step-icon-border-width: 1px;
    $step-icon-border-style: solid;
    $step-icon-border-color: $secondary;
      // if is Number or fonticon
      $mobile-step-icon-size: 18px;
      $step-icon-size: $mobile-step-icon-size;
      $step-icon-weight: normal;
      $step-icon-style: normal;
      $step-icon-color: $secondary;
      // if is Sprite
      $step-icon-url: url("../images/sprite-nav.png");
      // Bullet
      $step-bullet-width: 10px;
      $step-bullet-height: 10px;
      $step-bullet-bg: #fff;
      $step-bullet-radius: 100%;
      $mobile-step-bullet-check-size: 14px;
      $mobile-step-bullet-check-weight: 900;  // 100, 300, 400, 900 
        $step-bullet-check-size: 18px;
        $step-bullet-check-weight: 900; // 100, 300, 400, 900 

    // Step Line
    $mobile-step-line-margin: 3px;
    $mobile-horizontal-step-line-width: 1px;
    $mobile-horizontal-step-line-height: 1px;
    $mobile-vertical-step-line-width: 1px;
    $mobile-vertical-step-line-height: 30px;
    $mobile-step-line-style: solid;
    $mobile-step-line-color: rgba($secondary, .7);
    $horizontal-step-line-height: 1px;
    $vertical-step-line-width: 1px;
    $vertical-step-line-height: 50px;
    $step-line-margin: 3px;
    $step-line-style: solid;
    $step-line-color: $mobile-step-line-color;

// Active Step
$step-active-content-color: $secondary;
$step-active-content-weight: 600;
$step-active-content-style: italic;
$step-active-icon-color: $step-icon-color;
$step-active-icon-border-color: $step-icon-border-color;
$step-active-icon-bg-color: transparent;
$step-active-line-color: $step-line-color;

// Achieve Step
$step-achieve-content-color: $primary;
$step-achieve-content-weight: $step-active-content-weight;
$step-achieve-content-style: $step-content-style;
$step-achieve-icon-color: $primary;
$step-achieve-icon-border-color: $primary;
$step-achieve-icon-bg-color: $step-active-icon-bg-color;
$step-achieve-line-color: $primary;
