$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
) !default;

// Layout container padding
$mobile-container-padding-v: 0;
$mobile-container-padding-h: 12px;
$container-padding: $mobile-container-padding-v $mobile-container-padding-h;
$desktop-container-padding-v: 0;
$desktop-container-padding-h: 26px;
$desktop-container-padding: 0 $desktop-container-padding-h;

$primary: #7bed8c;
$secondary: #748a9d;
$tertiary: #44a993;
$quaternary: #11594b;

$app-bg-color: #fff;
$text-color: #748a9d;
$font-icon: "Font Awesome 6 Pro";
$btn-font-size: 18px;

$grid-row-columns: 12;

$global-radius: 6px;

// Notification
$alert-error-color: #f53242;
$alert-warning-color: #f57a32;

$vertical-layout-padding: 20px;
$horizontal-layout-padding: 20px;
$vertical-layout-padding-lg: 24px;
$horizontal-layout-padding-lg: 44px;
$vertical-layout-padding-xl: $vertical-layout-padding-lg;
$horizontal-layout-padding-xl: $horizontal-layout-padding-lg;

$active-color: #35dbbb;

$theme-email: #6250a1;
$theme-marketing: #ff6d80;
$theme-oav: #50bafc;
$theme-affiche: #ffb845;
$theme-plv: #7bb929;
$theme-autre: #5e7393;
$theme-page_g: #006a53;
$theme-rich_sms: #f3741f;

.flexNoWrap {
  flex-wrap: nowrap !important;
}

.minH-100 {
  min-height: 100%;
}

.flexbox {
  display: flex;
}
.flexColumn {
  flex-direction: column;
}
.flexWrap {
  flex-wrap: wrap;
}
.flex-0 {
  flex: 0;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.fullWidth {
  width: 100%;
}
.fullHeight {
  height: 100%;
}
.justifyCenter {
  justify-content: center;
}
.justifyBetween {
  justify-content: space-between;
}
.justifyAround {
  justify-content: space-around;
}
.justifyEvenly {
  justify-content: space-evenly;
}
.justifyEnd {
  justify-content: flex-end;
}
.alignCenter {
  align-items: center;
}
.alignBaseline {
  align-items: baseline;
}
.alignStart {
  align-items: flex-start;
}
.alignEnd {
  align-items: flex-end;
}
.alignStretch {
  align-items: stretch;
}

$max: 500;
$offset: 1;
$unit: "px"; // Feel free to change the unit.
@mixin list-loop($className, $styleName) {
  $i: 0;
  @while $i <= $max {
    #{$className + $i} {
      #{$styleName}: #{$i + $unit};
    }
    $i: $i + $offset;
  }
}

// Margins
@include list-loop(".mt-", "margin-top");
@include list-loop(".mr-", "margin-right");
@include list-loop(".mb-", "margin-bottom");
@include list-loop(".ml-", "margin-left");
@include list-loop(".mx-", "margin");
// Paddings
@include list-loop(".pt-", "padding-top");
@include list-loop(".pr-", "padding-right");
@include list-loop(".pb-", "padding-bottom");
@include list-loop(".pl-", "padding-left");
@include list-loop(".px-", "padding");

$map-for-positions: (
  "top": at top,
  "top-right": at top right,
  "right": at right,
  "bottom-right": at bottom right,
  "bottom": at bottom,
  "bottom-left": at bottom left,
  "left": at left,
  "top-left": at top left,
  "center": at center,
) !default;
