.pagination-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  .field {
    margin: 0;
    margin-left: 14px;
  }
}
.pagination {
  .page-number {
    color: rgba(#4D4F5C,.5);
    border: 1px solid #E8E9EC;
    background-color: #ffffff;
    margin: 0 2px;
    padding: 2px 10px;
    border-radius: 3px;
    &.active {
      background-color: $primary;
      color: #ffffff;
    }
  }
}

#cta {
  margin-top: 30px;
}
