* {
  box-sizing: border-box;
  touch-action: pan-y;
}
#root {
  flex-direction: column;
}
html {
  height: calc(var(--vh, 1vh) * 100);
}

body * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  color: $text-color;
}

body,
#root,
#wrapper {
  position: relative;
  // min-height: calc(var(--vh, 1vh) * 100);
  // max-height: calc(var(--vh, 1vh) * 100);
  min-height: 100dvh;
}

@supports (-webkit-touch-callout: none) {
  html {
    height: -webkit-fill-available;
  }
  body,
  #root,
  #wrapper {
    /* The hack for Safari */
    min-height: -webkit-fill-available;
    max-height: -webkit-fill-available;
  }
}
#root {
  @extend %flexbox;
  #wrapper {
    @extend %flexbox;
    width: 100%;
    background-color: $app-bg-color;
    main {
      order: -1;
      @extend %flexbox;
      flex-direction: column;
      flex: 1;
      position: relative;
      z-index: 1;
      @include media-breakpoint-up(sm) {
        order: unset;
      }
    }
  }
}

.content {
  @extend %flexbox;
  @include flex(1);
  flex-direction: column;
  &.fit-content {
    @include media-breakpoint-up(lg) {
      overflow: auto;
      .container {
        .page-content {
          display: flex;
          flex-direction: column;
          flex: 1;
          overflow: auto;
          padding: 12px $mobile-container-padding-h 0;
          margin: -12px (-$mobile-container-padding-h) 0;
          @include media-breakpoint-up(sm) {
            padding: 12px $desktop-container-padding-h 0;
            margin: -12px (-$desktop-container-padding-h) 0;
          }
          .box {
            &.closed {
              flex: 0 0 auto;
            }
            &.box-composteurs.opened {
              flex: 1 1 60%;
            }
            overflow: auto;
            flex: 1 1 100%;
            .box-header {
              position: sticky;
              top: 0;
              left: 0;
              z-index: 3;
              background-color: #fff;
            }
          }
          .table-wrapper {
            overflow: auto;
          }
        }
      }
    }
  }

  .container {
    &.overflow {
      overflow: auto;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 12px;
      background: rgba(#000, 0.2);
      &:hover {
        background: rgba(#000, 0.4);
      }
    }
    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }
  &.column {
    @include flex-direction(column);
  }
}

#admin-banner {
  background-color: darken($primary, 14%);
  color: #fff;
  text-align: center;
  padding: 0 12px;
  font-size: 15px;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 -4px 10px -1px rgba(0, 0, 0, 0.3);
  i {
    margin-right: 8px;
    font-size: 20px;
  }
  .btn {
    background-color: rgba(#000, 0.3);
    color: #fff;
    margin: 0 10px;
  }
}
#staging-banner {
  background-color: darken($primary, 20%);
  color: #fff;
  text-align: center;
  padding: 8px 0;
  text-transform: uppercase;
  font-size: 14px;
  width: 100%;
  height: 32px;
  // position: absolute;
  z-index: 1;
  i {
    margin: 0 14px;
  }
  + #admin-banner + #wrapper {
    max-height: calc(100vh - 32px - 50px);
    min-height: calc(100vh - 32px - 50px);
  }
  + #wrapper {
    // padding-top: 32px;
    max-height: calc(100vh - 32px);
    min-height: calc(100vh - 32px);
  }
}

#admin-banner + #wrapper {
  max-height: calc(100vh - 50px);
  min-height: calc(100vh - 50px);
}

.hide-mobile-menu {
  .mainnav {
    display: none;
    @include media-breakpoint-up(md) {
      display: flex;
    }
  }
  .page-header {
    @include media-breakpoint-down(lg) {
      position: sticky;
      top: 0;
      background: #f9f9f9;
      z-index: 999;
      padding: 12px 12px;
      margin: 0 -12px;
    }
  }
}
