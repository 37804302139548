a,
button {
  background: none;
  border: none;
  border-radius: 0;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;

  &:focus {
    outline: none;
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  i + span,
  span + i {
    margin-left: 12px;
  }
  i {
    font-size: 14px;
  }
  &.btn-primary {
    color: #fff;
  }
  &.btn-primary,
  &.btn-secondary {
    font-size: 14px;
    font-weight: 600;
    border-radius: 30px;
    line-height: 1;
    text-transform: uppercase;
    padding: 22px 36px;
    text-align: center;
    justify-content: center;
  }
  &.btn-small {
    font-size: 14px;
    padding: 8px 14px;
    i + span,
    span + i {
      margin-left: 14px;
    }
    i {
      font-size: 14px;
    }
  }
  &.btn-medium {
    font-size: 15px;
    padding: 15px 22px;
    i + span,
    span + i {
      margin-left: 14px;
    }
    i {
      font-size: 14px;
    }
  }
}

.table-header {
  .btn {
    padding: 14px 16px;
    i {
      font-size: 20px;
    }
  }
}

.btn.disabled,
.btn:disabled {
  opacity: 0.3;
  border-color: transparent;
}
