
.btn-secondary {
  color: #fff;
}

.flex-btn-group {
  @extend %flexbox;
  @include align-items(center);
  width: 100%;

  &.center {
    @include justify-content(center);
  }
  &.right {
    @include justify-content(flex-end);
  }
  &.between {
    @include justify-content(space-between);
  }
  &.around {
    @include justify-content(space-around);
  }
  .btn {
    margin: 0 8px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &.column {
    flex-direction: column;

  }
}

.btn-link {
  font-size: 14px;
  color: $secondary;
  i {
    font-size: 16px;
  }
}

.tooltip-btn .tooltip-text {
  visibility: hidden;
  background-color: rgba(#000, 0.6);
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  transform: translateX(-50%);
  top: 100%;
  left: 50%;
  z-index: 1;
  padding: 8px 12px;
  pointer-events: none;
}
.tooltip-btn:hover .tooltip-text {
  visibility: visible;
  display: inline-block;
}

.buttons-radio {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin: -4px;
  .btn-radio {
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
    margin: 4px;
    border-radius: 22px;
    padding: 6px 16px;
    color: #748A9D;
    &.btn-radio-active {
      color: #fff;
      background-color: $primary;
      border-color: $primary;
    }
  }
}


.img-link {
  display: inline-block;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  max-height: 440px;
  overflow: auto;
}


.choix-departements {
  margin-left: 0;
  .field-block {
    flex: auto;
  }
  .buttons-radio {
    justify-content: center;
  }
}

.btn-refresh {
  font-size: 34px;
  color: $secondary;
}