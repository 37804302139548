.card-stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F5F6FA;
  line-height: 1;
  padding: 20px 10px;
  text-align: center;
  height: 100%;
  .card-stats-data {
    color: $primary;
    font-size: 50px;
    + .card-stats-label {
      margin-top: 10px;
    }
  }
  .card-stats-label {
    display: flex;
    align-items: center;
    flex: 1;
    font-size: 20px;
  }
}