.steps {
  display: flex;
  justify-content: space-between;
  transition: all .2s ease-in-out;
  @include steps-orientation($mobile-step-orientation);
  @include media-breakpoint-up(lg) {
    @include steps-orientation($step-orientation);
  }
  padding: $mobile-steps-padding-t $mobile-steps-padding-horizontal $mobile-steps-transparent-padding-b;
  margin: $mobile-steps-margin;
  width: 100%;
  @if $isIconStyle == "number" {
    counter-reset: section;
  }
  @include media-breakpoint-up(lg) {
    margin: $steps-margin;
    padding: $steps-padding-t $steps-padding-horizontal $steps-transparent-padding-b;
  }
}

