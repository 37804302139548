.small-text {
  font-size: 13px;
}

.text-left {
  text-align: left !important;
  justify-content: flex-start !important;
}
.text-center {
  text-align: center !important;
  justify-content: center !important;
}
.text-right {
  text-align: right !important;
  justify-content: flex-end !important;
}

.data-value-big {
  font-size: 50px;
  display: flex;
  align-items: center;
  span {
    margin-right: 12px;
  }
}

.tag-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
} 
.tag {
  color: #fff;
  background-color: $primary;
  padding: 3px 8px;
  border-radius: 4px;
  margin: 3px;
  text-align: center;
}

.field-indications {
  color: $secondary;
  text-align: center;
  font-style: italic;
  margin-bottom: 14px;
}

// .commentaire {
//   border-left: 3px solid #ccc;
//   padding: 14px;
//   background: #efefef;
//   margin-bottom: 6px;
//   .commentaire-entete {
//     display: flex;
//     align-items: baseline;
//     margin-bottom: 4px;
//   }
//   .commentaire-auteur {
//     font-weight: 600;
//   }
//   .commentaire-date {
//     font-size: 14px;
//   }
//   .commentaire-content {
//     font-size: 14px;
//     p {
//       margin-bottom: 0;
//     }
//   }
//   @for $i from 1 through 100 {
//     &:nth-child(#{$i}) {
//       margin-left: calc(#{$i} * 4px - 4px);
//     }
//   }
//   &:nth-child(1) {
//     margin-left: 0;
//   }
// }

