.login {
  .box {
    .logo {
      flex-direction: column;
    }
    .btn-password {
      font-size: 15px;
      font-style: italic;
      text-decoration: underline;
      color: $btn-password-color;
      margin-top: 30px;
    }
    .btn-login {
      margin-top: 14px;
      font-size: 20px;
      font-weight: 500;
      padding: 10px;
      text-transform: uppercase;
    }
  }
}
