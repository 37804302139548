header {
  background-color: #f0f4f8;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
  .last-maj {
    font-size: 13px;
  }
  a, button {
    display: flex;
    align-items: center;
  }

  .nav-link {
    margin: 0 6px;
    color: $text-color;
    span {
      font-size: 15px;
    }
    i + span {
      margin-left: 6px;
    }
  }  
  nav {
    display: flex;
    align-items: center;
  }
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    color: $primary;
    font-size: 50px;
  }
  i + span {
    margin-left: 12px;
    color: $text-color;
  }
  font-size: 20px;
  font-weight: 600;
  color: $text-color;
}