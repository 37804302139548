.tooltip-container {
  position: relative;
  margin-left: 8px;
  i {
    cursor: pointer;
    color: $primary;
    &:hover {
      + span {
        display: block;
      }
    }
  }
  span {
    display: none;
    position: absolute;
    bottom: 30px;
    right: 0;
    background-color: $primary;
    color: #fff;
    font-size: 12px;
    width: 110px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    text-align: center;
    padding: 8px;
    border-radius: 4px;
    &:after {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      bottom: -6px;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid #44a993;
    }
  }
}
