table.table {
  color: $secondary;
  background-color: #fff;
  width: 100%;
  overflow-x: hidden;
  .form-control,
  .form-select {
    background-color: #fff;
    // position: absolute;
  }
  th,
  td {
    border-color: #f5f6fa;
  }
  thead {
    tr {
      background-color: #f5f6fa;
      th {
        font-weight: normal;
        font-size: 15px;
        vertical-align: top;
        a {
          white-space: nowrap;
        }
        &.intervale {
          width: 140px;
        }
        &.statut {
          max-width: 140px;
        }
        .th-title {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          span {
            margin-right: 10px;
          }
        }
        // &.two-btn {
        //   width: 105px;
        // }
        .field-date-interval {
          margin-top: 4px;
          flex-direction: column;
          .input-date {
            label {
              display: inline-block;
            }
          }
          > div:first-child {
            margin-bottom: 3px;
          }
          input[type="date"] {
            width: 90px;
            margin: 0;
            overflow: hidden;
            color: $text-color;
          }
          input[type="date"]::-webkit-calendar-picker-indicator {
            position: absolute;
            opacity: 0;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            cursor: pointer;
          }
          i {
            display: none;
          }
        }
        .field {
          margin-bottom: 0;
          width: 100%;
          min-height: 25px;
          position: relative;
          &.select-statut {
            .field-block {
              position: static;
            }
          }
          .field-block {
            position: absolute;
            width: 100%;
          }
        }
        input,
        select,
        .v-select .vs__dropdown-toggle {
          margin-top: 4px;
          border-radius: 5px;
          border: none;
          width: 100%;
          padding: 2px 6px;
          font-size: 14px;
        }

        .v-select {
          width: 100%;
          .vs__dropdown-toggle {
            box-shadow: none;
            background-color: #fff;
            min-width: 100%;
            .vs__selected {
              margin: 2px;
            }
            .vs__search {
              flex: 1;
              padding-top: 0;
              padding-bottom: 0;
              line-height: 1.5;
              font-size: 14px;
            }
          }
        }
        input[type="date"]::-webkit-calendar-picker-indicator {
          position: absolute;
          right: 6px;
          top: 6px;
          margin: 0;
          padding: 0;
          cursor: pointer;
        }
      }
    }
  }
  > :not(:first-child) {
    border-top: 0;
  }
  tbody {
    tr {
      td {
        font-size: 14px;
        vertical-align: middle;
        .btn {
          color: $secondary;
          padding: 4px 6px;
        }
        &.image {
          text-align: center;
        }

        ul {
          padding-left: 20px;
        }
      }
    }
  }
}

.table-toolbar {
  margin-bottom: 20px;
  .btn-primary {
    color: #666666;
    border: 1px solid #e0e0e0;
    font-size: 16px;
    background-color: transparent;
    padding: 10px 20px;
  }
}

.table-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  @include media-breakpoint-up(md) {
    flex-direction: row;
    margin: 60px auto;
  }
}
.table-header-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  @include media-breakpoint-up(md) {
    flex-direction: row;
    margin-bottom: 0px;
  }
  .field {
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
  .custom-radio-wrapper {
    @include media-breakpoint-up(md) {
      margin-left: 60px;
    }
  }
}
.table-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 10px 0;
  .btn + .pagination-wrapper {
    margin-left: 12px;
  }
  .pagination-wrapper + .perpage-selector {
    margin: 0;
    margin-left: 12px;
  }
  .perpage-selector {
    .form-select {
      padding-top: 2px;
      padding-bottom: 2px;
      color: rgba(#4d4f5c, 0.5);
      border: 1px solid #e8e9ec;
      background-color: #ffffff;
      border-radius: 3px;
    }
  }
}
