.box-highlight {
  background: hsl(130, 54%, 93%);
  border: 1px solid hsl(130, 54%, 73%);
  border-radius: 20px;
  padding: 20px;
  margin-top: 14px;
  label {
    text-align: center;
    font-weight: bold;
    color: hwb(130 19% 25%);
  }
  .dropzone .btn {
    border: 1px solid hsl(130, 54%, 73%);
    background: #cae7ce;
    i {
      color: #79c185;
      opacity: 1;
    }
  }
}
