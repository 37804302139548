@mixin step-wrapper {
  .step-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    @if $step-bg-style == "solid" {
      flex: 1;
    }
    @if $step-bg-style == "transparent" {
      @include flex(1 0 auto);
    }
    &:last-child {
      @include flex(none);
      padding-bottom: 0;
      .step-line {
        display: none;
      }
    }
    .step-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding: 0;
      z-index: 2;
      .step-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: $mobile-step-icon-width;
        height: $mobile-step-icon-height;
        @include media-breakpoint-up(lg) {
          width: $step-icon-width;
          height: $step-icon-height;
        }
        border-radius: $step-icon-radius;
        border: $mobile-step-icon-border-width $mobile-step-icon-border-style
          $mobile-step-icon-border-color;
        @include media-breakpoint-up(lg) {
          border-width: $step-icon-border-width;
        }
        @if $step-bg-style == "transparent" {
          background-color: transparent;
        }
        @if $step-bg-style == "solid" {
          background-color: $step-icon-bg-color;
        }
        @if $isIconStyle == "number" {
          &:before {
            counter-increment: section;
            content: "" counter(section);
          }
        }
        @if $isIconStyle == "sprite" {
          background-image: $step-icon-url;
          background-position: #{$step-icon-width * -2} #{$step-icon-height * 0};
        } @else {
          color: $step-icon-color;
          font-size: $mobile-step-icon-size;
          @include media-breakpoint-up(lg) {
            font-size: $step-icon-size;
          }
          font-weight: $step-icon-weight;
          font-style: normal;
        }
      }
      .step-content {
        position: absolute;
        display: flex;
        flex-direction: column;
        text-align: $step-content-align;
        color: $mobile-step-content-color;
        font-size: $mobile-step-content-size;
        font-weight: $mobile-step-content-weight;
        font-style: $mobile-step-content-style;
        top: calc(#{$mobile-step-icon-height} + #{$mobile-step-content-margin-t});
        @include media-breakpoint-up(lg) {
          color: $step-content-color;
          font-size: $step-content-size;
          font-weight: $step-content-weight;
          font-style: $step-content-style;
          top: calc(#{$step-icon-height} + #{$step-content-margin-t});
        }
        @if $isStepTextWidthConstrained == "yes" {
          width: $step-content-width;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        } @else {
          @if $step-bg-style == "transparent" {
            white-space: nowrap;
          }
        }
        .step-content {
          font-size: $step-subtext-size;
          font-weight: $step-subtext-weight;
          font-style: $step-subtext-style;
        }
      }
    }
    .step-line {
      width: 100%;
      border-top: $mobile-horizontal-step-line-height $mobile-step-line-style
        $mobile-step-line-color;
      margin: 0 $mobile-step-line-margin;
      @include media-breakpoint-up(lg) {
        height: $horizontal-step-line-height;
        margin: 0 $step-line-margin;
      }
    }
    &.active {
      .step-icon {
        color: $step-active-icon-color;
        border-color: $step-active-icon-border-color;
        @if $step-bg-style == "transparent" {
          background-color: transparent;
        }
        @if $step-bg-style == "solid" {
          background-color: $step-active-icon-bg-color;
        }
        @if $isIconStyle == "sprite" {
          background-position: #{$step-icon-width * 0} #{$step-icon-height * 0};
        }
        @if $isIconStyle == "bullet" {
          &:before {
            content: "";
            width: $step-bullet-width;
            height: $step-bullet-height;
            background-color: $step-bullet-bg;
            border-radius: $step-bullet-radius;
          }
        }
      }
      .step-content {
        color: $step-active-content-color;
        font-weight: $step-active-content-weight;
        font-style: $step-active-content-style;
      }
      .step-line {
        border-color: $step-active-line-color;
      }
    }
    &.validated {
      .step-icon {
        border-color: $step-achieve-icon-border-color;
        color: $step-achieve-icon-color;
        background-color: $step-achieve-icon-bg-color;
        @if $isIconStyle == "sprite" {
          background-position: #{$step-icon-width * -1} #{$step-icon-height * 0};
        }
        @if $isIconStyle == "bullet" {
          &:before {
            content: "\f00c";
            font-family: $font-icon;
            font-size: $mobile-step-bullet-check-size;
            font-weight: $mobile-step-bullet-check-weight;
            @include media-breakpoint-up(lg) {
              font-size: $step-bullet-check-size;
              font-weight: $step-bullet-check-weight;
            }
          }
        }
      }
      .step-content {
        color: $step-achieve-content-color;
        font-weight: $step-achieve-content-weight;
      }
      .step-line {
        border-color: $step-achieve-line-color;
      }
    }
  }
}

@mixin steps-orientation($orientation) {
  @if $orientation == "horizontal" {
    @include step-wrapper;
  }
  @if $orientation == "vertical" {
    @include step-wrapper;
    flex-direction: column;
    .step-wrapper {
      flex-direction: column;
      align-items: flex-start;
      .step-block {
        flex-direction: row;
        align-items: flex-start;
        padding-bottom: 20px;
        .step-content {
          position: static;
          align-items: flex-start;
          flex-direction: column;
          margin-left: $step-content-margin-l;
        }
      }
      .step-line {
        position: absolute;
        width: 0;
        border-left: $mobile-vertical-step-line-width
          $mobile-step-line-style
          $mobile-step-line-color;
        border-top: 0;
        height: calc(100% - #{$mobile-step-icon-height});
        margin-top: $mobile-step-icon-height;
        margin-left: calc(
          (#{$mobile-step-icon-width} / 2) - calc(#{$mobile-vertical-step-line-width} / 2)
        );
        @include media-breakpoint-up(lg) {
          border-left: $vertical-step-line-width $step-line-style $step-line-color;
          height: calc(100% - #{$step-icon-height});
          margin-top: $step-icon-height;
          margin-left: calc((#{$step-icon-width} / 2) - calc(#{$vertical-step-line-width} / 2));
        }
      }
    }
  }
}
