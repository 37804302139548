.page-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    margin: 60px auto;
    align-items: flex-end;
  }
  .field-date-interval > div input,
  .form-select {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 16px;
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid #d7dae2;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
  }
  .field-date-interval > div input {
    margin: 0 !important;
  }
  .field {
    margin-left: 8px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
  .page-header-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    @include media-breakpoint-up(md) {
      flex-direction: row;
      margin-bottom: 0px;
    }

    .custom-radio-wrapper {
      @include media-breakpoint-up(md) {
        margin-left: 60px;
      }
    }
  }
  .router-link-active {
    color: $text-color;
  }
  h1 {
    font-size: 60px;
    flex: 1;
    text-transform: uppercase;
    text-align: center;
  }
  .fields-block {
    display: flex;
    @include media-breakpoint-up(md) {
      margin-left: 40px;
    }
  }
}

.page-content {
  display: flex;
}

h2 {
  color: $primary;
  margin-bottom: 30px;
}

h3 {
  font-size: 25px;
  color: $secondary;
  font-weight: bold;
}

.data-list {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    padding-bottom: 10px;
  }
  .data {
    display: flex;
    .label-data {
      white-space: nowrap;
      margin-right: 6px;
    }
    .answer-data {
      display: flex;
      flex-direction: column;
      a {
        color: $secondary;
      }
    }
  }
}

// input[type="date"]::-webkit-calendar-picker-indicator {
//   position: absolute;
//   right: 6px;
//   top: 16px;
//   margin: 0;
//   padding: 0;
//   cursor: pointer;
// }

.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $navbar-bkg-color;
  padding: 10px 30px;
}
.content {
  padding: 20px 30px;
}

.infos {
  h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  a.lien-dossier {
    display: inline-block;
    color: $text-color;
    text-decoration: underline;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
  }
}

.field-date-interval {
  .input-date {
    display: flex;
    align-items: center;
    label {
      display: none;
      font-size: 12px;
      margin-right: 8px;
    }
  }
}
